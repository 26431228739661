import { setIsPreBottom, setIsNotPreBottom } from "/store/ui/badge-store";
import { scrollToEase } from "/helpers/constants";
import { store } from "/store/store";
import { gsap } from "/gsap";

export function onFooterIntersect(inView, entry) {
  if (inView && window.innerWidth >= 1536) {
    store.dispatch(setIsPreBottom());
  } else {
    store.dispatch(setIsNotPreBottom());
  }
}

export function badgeScrollToSection({
  isBadgePreBottom,
  isBadgeFixed,
  isTablet,
  currentSection,
  badgeType,
  jobsSectionAnimated = null,
}) {
  if (isBadgePreBottom) return;

  const sectionsAmount =
    document.querySelectorAll("[data-section]")?.length ?? 0;
  const header = document.querySelector("#header");
  let offset = header?.getBoundingClientRect()?.height ?? 0;
  let duration = 0.65;

  let animated = false;

  switch (badgeType) {
    case "careers":
      if (!isBadgeFixed) {
        const target = document.querySelector("#jobs");
        const targetTop = target.getBoundingClientRect().top;
        duration = 1.2;

        gsap.to(window, duration, {
          scrollTo: { y: targetTop, offsetY: offset, ease: scrollToEase },
        });
      } else {
        const nextSection =
          currentSection < sectionsAmount - 1
            ? currentSection + 1
            : currentSection;

        gsap.to(window, duration, {
          scrollTo: {
            y: `[data-section="${nextSection}"]`,
            offsetY: offset,
            ease: scrollToEase,
          },
        });
      }
      break;
    case "home":
      if (!isBadgeFixed) {
        gsap.to(window, duration, {
          scrollTo: {
            y: "#explore",
            offsetY: offset,
            ease: scrollToEase,
          },
        });
      } else {
        if (!animated) {
          animated = true;

          const nextSection =
            currentSection < sectionsAmount - 1
              ? currentSection + 1
              : currentSection;
              
          gsap.to(window, duration, {
            scrollTo: {
              y: `[data-section="${nextSection}"]`,
              offsetY: offset,
              ease: scrollToEase,
              onComplete() {
                animated = false;
              },
            },
          });
        }
      }

      break;
    case "products":
      if (!isBadgeFixed) {
        gsap.to(window, duration, {
          scrollTo: {
            y: '[data-section-name="Voluum"]',
            offsetY: offset,
            ease: scrollToEase,
          },
        });
      }
      break;
    default:
      break;
  }
}
